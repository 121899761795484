import React from "react"

const Colorcard = ({ name, codeHex, codeRgb = null, codeHsl = null, codeCmjn = null}) => {
    return (
        <li className="rounded-md mx-4 mb-8 pt-20 shadow-lg w-full md:w-auto" style={{
            backgroundColor: codeHex,
            minWidth: '320px'
        }}>
            <span className="block text-2xl p-4 font-bold" style={{
                color: getContrast(codeHex)
            }}>{name}</span>
            <span className="block bg-white p-4 rounded-b-md">
                <span className="block text-base"><span className="inline-block w-16 uppercase text-gray-500">HEX</span>{codeHex}</span>
                {codeRgb !== null &&
                    <span className="block text-base"><span className="inline-block w-16 uppercase text-gray-500">RGB</span>{codeRgb}</span>
                }
                {codeHsl !== null
                    ? <span className="block text-base"><span className="inline-block w-16 uppercase text-gray-500">HSL</span>{codeHsl}</span>
                    : <span className="block text-base"><span className="inline-block w-16 uppercase text-gray-500">HSL</span>{getHSL(codeHex)}</span>
                }
                {codeCmjn !== null &&
                    <span className="block text-base"><span className="inline-block w-16 uppercase text-gray-500">CMJN</span>{codeCmjn}</span>
                }
            </span>
        </li>
    )
}

const getContrast = (hex) => {
    let r = parseInt(hex.substr(1, 2), 16),
        g = parseInt(hex.substr(3, 2), 16),
        b = parseInt(hex.substr(5, 2), 16),
        yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
    return (yiq >= 128) ? 'black' : 'white'
}

const getHSL = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex),
        r = ( parseInt(result[1], 16) / 255 ),
        g = ( parseInt(result[2], 16) / 255 ),
        b = ( parseInt(result[3], 16) / 255 );

    let max = Math.max(r, g, b), min = Math.min(r, g, b),
        h, s, l = (max + min) / 2;

    if(max === min){
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export default Colorcard