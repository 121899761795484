import React from "react"
import componentStyles from "./fontcard.module.css" 

const Fontcard = ({ name, family, value, fweight='400', fstyle='normal' }) => {
    return (
        <div className={componentStyles.card}>
            <div className={componentStyles.cardname}>{name}</div>
            <div className={componentStyles.cardlargepreview} style={{
                fontFamily: value,
                fontWeight: fweight,
                fontStyle: fstyle
            }}>aA</div>
            <div className={componentStyles.cardsmallpreview} style={{
                fontFamily: value,
                fontWeight: fweight,
                fontStyle: fstyle
            }}>
                <div>ABCDEFGHIJKLMNOPQRSTUVWXYZ</div>
                <div>abcdefghijklmnopqrstuvwxyz</div>
                <div>1234567890(,.;:?!$&*)</div>
            </div>
            <div className={componentStyles.cardparameters}>
                <div><b>Famille:</b> {family}</div>
                <div><b>Graisse:</b> {fweight}</div>
                <div><b>Style:</b> {fstyle}</div>
            </div>
        </div>
    )
}

export default Fontcard