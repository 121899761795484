import React from "react"

const Dirblock = ({ name, url }) => {
    return (
        <a href={url} target="_blank" rel="noreferrer" className="block mt-8 mb-16 border border-gray-200 shadow-lg rounded-md">
            <span className="flex flex-col md:block md:clear p-8">
                <span className="order-2 w-full md:float-right md:w-auto bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-8 roundedbg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-center">Afficher le dossier</span>
                <span className="order-1 md:block text-2xl font-bold mb-4 md:mb-0">{name}</span>
            </span>
        </a>
    )
}

export default Dirblock