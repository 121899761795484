import React from "react"

const Colorlist = ({ children }) => {
    return (
        <ul className="flex flex-wrap -mx-4">
            {children}
        </ul>
    )
}

export default Colorlist