import React from "react"

const Previewblock = ({ file, label, bgclass = 'bg-white' }) => {
    return (
        <figure className="block mt-8 mb-16 border border-gray-200 shadow-lg rounded-md">
            <div className={`p-8 rounded-t-md ${bgclass}`}>
                <img src={file} alt={label} className="w-full h-auto mx-auto" style={{maxWidth: "640px"}} />
            </div>
            <figcaption className="block text-center px-8 py-4 bg-gray-100 border-t border-gray-200 text-sm text-gray-500">{label}</figcaption>
        </figure>
    )
}

export default Previewblock