import Linkblock from "../../../../src/components/linkblock/linkblock";
import Previewblock from "../../../../src/components/previewblock/previewblock";
import ImageLogoColor from "../../../../src/images/logo GJ Cycling Shop.be - logo horizontal - quadri.png";
import ImageLogoBlack from "../../../../src/images/logo GJ Cycling Shop.be - logo horizontal - NB - ombrage transparence.png";
import ImageLogoWhite from "../../../../src/images/logo GJ Cycling Shop.be - logo horizontal - white.png";
import * as React from 'react';
export default {
  Linkblock,
  Previewblock,
  ImageLogoColor,
  ImageLogoBlack,
  ImageLogoWhite,
  React
};